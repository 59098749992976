import React from 'react'
import  { useRef }  from 'react'
import emailjs  from '@emailjs/browser'
import './Join.css'

const Join = () => {
    const form = useRef()        
    
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_av001no', 'template_eshmlka',form.current, '4wKR3rA8AMrpjOfe5')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div className='join' id="join-us">

        <div className='left-j'>
            <hr/>
           
   <div>
    <span className='stroke-text'>READY  TO </span>
    <span> LEVEL  UP </span>
   </div>
   <div>
    <span>YOUR  BODY  </span>
    <span className='stroke-text'>WITH  US</span>
   </div>

        </div>
 <div className='right-j'>
<form ref={form} className='email-container' onSubmit={sendEmail}>

    <input type="Email" name="user_email" placeholder='Enter your Email' />

 <button className='btn btn-j'>JOIN NOW</button>

</form>
</div>
    </div>
  )
}

export default Join