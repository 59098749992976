import React from 'react'
import Header from './Header/Header'
import Heart from "../../assets/heart.png"
import right_front_img  from "../../assets/right_front_img.png"
import hero_back_img  from "../../assets/hero_back_img.png"
import calories from "../../assets/calories.png"
import { motion  } from 'framer-motion'
import  "./Hero.css"
import NumberCounter from 'number-counter'
const Hero = () => {
    const transition={type:'spring', duration :3}

    const mobile= window.innerWidth<=768? true :false;
  return (
    <div  className='hero' id="home"> 

    <div className='blur hero-blur'></div>

        {/* the best add area */}
<div className="left-h">
<Header/>

<div className='the-best-ad'>
    <motion.div
        initial={{left:mobile? '178px':"200PX"}}
        whileInView={{left:'6px'}}

        transition={{...transition ,type:'tween'}}
>

    </motion.div>
    <span>The Best Fitness club in town </span>




</div>


<div className='hero-text'>

<div>
    <span className='stroke-text'>Shape </span><span>Your</span>

    </div>


<div>
    <span>Ideal Body </span>
</div>
<div className=""> 
    <span>
    IN HERE WE WILL HELP YOU TO SHAPE AND BUILD YOUR IDEAL BODY AND LIVE UP YOUR LIFE TO FULLEST 

    </span>
    </div>

</div>

{/* figures */}
<div className='figure'>
    <div>
        <span>
            
            
        <NumberCounter end={140}  start={100} delay='4' preFix="+"/>
            
         </span>
        <span>Expert couches</span>
    </div>
    
    <div>
        <span>

        <NumberCounter end={952}  start={800} delay='4' preFix="+"/>

        </span>
        <span>Members joined</span>
    </div>
    
    <div>
        <span>
        <NumberCounter end={50}  start={10} delay='4' preFix="+"/>
        </span> 
        <span>Fitness progrma</span>
    </div>
    
</div>


{/* buttons */}
<div className='hero-button'>
    <button className='btn'>Get started</button>
    <button className='btn'>Learn more  </button>

</div>

</div> 


<div className="right-h">
  
  <button  className='btn'>Join Now</button>


 <motion.div 
        initial={{right:'-1rem'}}
        whileInView={{right:'2rem'}}

        transition={transition}
 
 
 className='heart-rate'>
    <img src={Heart} alt="" />

    <span> Heart rate</span>
    <span>116 BPM</span>

 </motion.div>
 
 {/* more imageess */}
 
 <img src={right_front_img} alt=""  className='hero-image'/>

 <motion.img
  initial={{right:'11rem'}}
  whileInView={{right:'20rem'}}
transition={transition}

 
 src={hero_back_img} alt="" className='hero-image-back'/>

{/* calorie */}


<motion.div 
   initial={{right:'37rem'}}
   whileInView={{right:'28rem'}}
   transition={transition}
   
   className="calories"
   >

    <img src={calories}  alt="" />
    <div>
        <span>Calories Burned </span>
        <span>220 Kcal</span>
    </div>
</motion.div>


</div>
</div>
  )
}

export default Hero